import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/logo.png';
import os from '../assets/os.png'
import twitter from '../assets/twitter.png'
import discord from '../assets/discord.png'

const opensea = () => {
	window.open("#");
}

const dis = () => {
	window.open("https://discord.com/invite/FVptV7c7gw");
}

const tweet = () => {
	window.open("https://twitter.com/clubofshadows");
}


const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">
                <div class="footer">
                    <div><img class="logoF" src={logo} /></div>
                    <div class="icons">
                        <div class="discord"><img class="osPic" onClick={opensea} src={os} /></div>
                        <div class="discord"><img class="osPic" onClick={tweet} src={twitter} /></div>
                        <div class="discord"><img class="osPic" onClick={dis} src={discord} /></div>
                     </div>
                    <div class="copyright">Copyright © 2022 COS. All Rights Reserved</div>
                </div>

            </div>
        )
    }
}

export default Footer;

