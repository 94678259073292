import React, { Component } from 'react';
import '../App.css';
import face from '../assets/ninjaface.png';
import icon from '../assets/shuriken (1).png';
import sword from '../assets/sword.png';
import line from '../assets/line.png';

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);


class Content extends Component {

  async componentDidMount() {

  }

  render() {
    return (
      <div class="boxWrap2Content">

        <div class="contentSection">
         <p>Discover a world of exclusive benefits and opportunities as a future holder of a Shadow Ninja NFT. Get educated on how to generate income online, get investment advice from experts and connect with fellow ninjas, contribute to the fight against bullying. All this and more to come for our future ninja holders!</p>
         <p>Join a community that is here to make a mark and stay at all times. We fight for and with our holders and welcome anyone with a passion for NFTs, web3, and cryptocurrency.</p>

        </div>
      </div>
    )
  }
}

export default Content;