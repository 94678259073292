import React, { Component } from 'react';
import '../App.css';
import face from '../assets/ninjaface.png';
import icon from '../assets/shuriken (1).png';
import sword from '../assets/sword.png';
import line from '../assets/line.png';

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);


class Team extends Component {

  async componentDidMount() {

  }

  render() {
    return (
      <div class="boxWrap2Team">

        <div class="storyH">Meet The Ninja Team</div>
        <div class="storyH2">The COS Team</div>

        <div class="teamSection">
          <div class="team1">
            <div class="manDiv"><img class="man" src={face} /></div>
            <img class="sword" src={sword}/>
            <div class="teamName">Shikaku</div>
            <img class="line" src={line}/>
            <div class="tAndName">
              <div class="memName"><img src={icon} /> Business in Webbdesign, marketing and graphic design</div>
              <div class="memName"><img src={icon} /> In NFT & Crypto space since 2018</div>
              <div class="memName"><img src={icon} /> Large contact network</div>
              <div class="memName"><img src={icon} /> Experience as an active NFT holder in several communitys over the past few years</div>
              <div class="memName"><img src={icon} /> Crypto investor</div>
              <div class="memName"><img src={icon} /> 10+ years experience of leadership</div>

            </div>
          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={face} /></div>
            <img class="sword" src={sword}/>

            <div class="teamName">Ishikawa</div>
            <img class="line" src={line}/>

            <div class="tAndName">
              <div class="memName"><img src={icon} /> Business in Webbdesign, marketing and graphic design</div>
              <div class="memName"><img src={icon} /> In NFT & Crypto space since 2019</div>
              <div class="memName"><img src={icon} /> Music producer</div>
              <div class="memName"><img src={icon} /> Graphic designer</div>
              <div class="memName"><img src={icon} /> Experience as an active NFT holder in several communitys over the past few years</div>
              <div class="memName"><img src={icon} /> Crypto investor </div>

            </div>
          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={face} /></div>
            <img class="sword" src={sword}/>
            <div class="teamName">Akihiko</div>
            <img class="line" src={line}/>

            <div class="tAndName">
              <div class="memName"><img src={icon} /> Forex and ETF trader</div>
              <div class="memName"><img src={icon} /> In the Crypto space since 2018</div>
              <div class="memName"><img src={icon} /> Engaged NFT enthusiast In the NFT space since 2020</div>
              <div class="memName"><img src={icon} /> Three years of discord administration and leadership</div>
              <div class="memName"><img src={icon} /> Wide contact network in the NFT space</div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default Team;