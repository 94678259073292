import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import Carousel from './carousel';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                <div class="storyH">WHY DID WE CREATE CLUB OF SHADOWS</div>
                <div class="storyH2">The true story</div>

                <div class="about">
                    
                    <div class="storyCon">
                        <div>Why we created a NFT community</div>

                        <p> Shikaku and Ishikawa have been thru most of their time in the NFT space together, joining the next community after the next, but most communities resulted in dissapointment and we lost our time along side with the money we invested.</p>
                        <p> So we created this idea that now has became reality, along our way of planning we find a perfect match in Akihiko to join our team with his valuable experience from the NFT space and from NFT communitys.</p>
                        <p> Now we want to give you a trusthworthy place to be and invest in, a long term project that works for and with their holders.</p>
                        <p> Together we will create the best NFT community in the space, the Club of Shadows.</p>

                    </div>

                    <div class="storyCon2">
                        <div>Why ninjas?</div>
                        <p>During the planning process we had a few important subjects, one was what do we want to stand for. We proudly choose to stand behind Anti-bullying and being a part of fighting against it, since we wanted to fight we had to bring the best fighters to the war, so we have now created 7,777 unique Shadow Ninjas for our community.</p>
                        <p>Our aim for the future is to build a strong family of ninjas and an active organization that always work to bring the best for all holders. The goal is that every Shadow Ninja is priceless, but do you have what it takes to join the Club Of Shadows family?</p>

                    </div>

                </div>
                <Carousel />

            </div>
        )
    }
}

export default Story;

