import React, { Component } from 'react';
import '../App.css';
import $ from "jquery";
import '../newRoadmap.css'
import icon from '../assets/rmI.png';
import stickyNinja from '../assets/1436.png';
import nft1Pic from '../assets/1159.jpg';
import nft2Pic from '../assets/21.jpg';
import nft3Pic from '../assets/1045.jpg';

window.addEventListener("scroll", reveal);

$(window).on('scroll', function () {
  function isScrollIntoView(elem, index) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(window).height() * .5;
    if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
      $(elem).addClass('active');
    }
    if (!(elemBottom <= docViewBottom)) {
      $(elem).removeClass('active');
    }
  }
  var timeline = $('.vertical-scrollable-timeline li');
  Array.from(timeline).forEach(isScrollIntoView);
});
function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

$(window).on('scroll', function () {
  function isScrollIntoView(elem, index) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(window).height() * .5;
    if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
      $(elem).addClass('active');
    }
    if (!(elemBottom <= docViewBottom)) {
      $(elem).removeClass('active');
    }
  }
  var timeline = $('.vertical-scrollable-timeline li');
  Array.from(timeline).forEach(isScrollIntoView);
});


class Team extends Component {

  async componentDidMount() {

  }

  render() {
    return (
      <div class="boxWrap2Rm">

        <div class="storyH">Ninja Roadmap</div>
        <div class="storyH2">Club Of Shadows</div>

        <div class="timeline-container">
          <ul class="vertical-scrollable-timeline">
            <li>
              <h2>set up COS</h2>
              <p><img class="rmNote" src={icon} />Website </p>
              <p><img class="rmNote" src={icon} />Twitter.YT & IG</p>
              <p><img class="rmNote" src={icon} />Discord</p>
              <p><img class="rmNote" src={icon} />Graphic content</p>
              <p><img class="rmNote" src={icon} />Roadmop</p>
              <p><img class="rmNote" src={icon} />Music Video</p>
              <p><img class="rmNote" src={icon} />Rap song for CoS</p>
              <p><img class="rmNote" src={icon} />3D Video</p>
              <p><img class="rmNote" src={icon} />Mint date & price</p>
              <div class="icon-holder">
                <img class="footPrint2" src={icon} />

              </div>
            </li>
            <li>
              <h2>Connecting & giveaways</h2>

              <p><img class="rmNote" src={icon} />Giveaways</p>
              <p><img class="rmNote" src={icon} />Live DJ event in discord</p>
              <p><img class="rmNote" src={icon} />OG spots con be claimed</p>
              <p><img class="rmNote" src={icon} />WL spots can be claimed</p>
              <p><img class="rmNote" src={icon} />Giveaways</p>
              <p><img class="rmNote" src={icon} />More discord #chonnels</p>
              <p><img class="rmNote" src={icon} />Quest games & missions</p>
              <p><img class="rmNote" src={icon} />Launch ropsong with musicvideo</p>
              <p><img class="rmNote" src={icon} />launch mint</p>

              <div class="icon-holder">
                <img class="footPrint2" src={icon} />
              </div>
            </li>
            <li>
              <h2>Education & investments</h2>

              <p><img class="rmNote" src={icon} />Learn digital income skills with full guides</p>
              <p><img class="rmNote" src={icon} />Trading experts wilt share analysis and tips in privote channels</p>
              <p><img class="rmNote" src={icon} />Community investments</p>
              <p><img class="rmNote" src={icon} />Investment room (share your prediction)</p>
              <p><img class="rmNote" src={icon} />Free access to online education and courses</p>
              <div class="icon-holder">
                <img class="footPrint2" src={icon} />
              </div>
            </li>

            <li>
              <h2>Benefits & movie stars</h2>

              <p><img class="rmNote" src={icon} />Diamond Holders split on 30% of profit on all upcomming collections</p>
              <p><img class="rmNote" src={icon} />CoS Merch</p>
              <p><img class="rmNote" src={icon} />Your NFTs wilt star in youtube video project and next music video</p>
              <p><img class="rmNote" src={icon} />Access to free marketing (To thousands of people)</p>
              <p><img class="rmNote" src={icon} />Investment room (share your prediction)</p>
              <p><img class="rmNote" src={icon} />Tickets to sport/music events</p>
              <p><img class="rmNote" src={icon} />OG collection, 1 NFT for free to OGs</p>
              <div class="icon-holder">
                <img class="footPrint2" src={icon} />
              </div>
            </li>

          </ul>
        </div>
        <img class="stickyNinja" src={stickyNinja} />

        <div class="container">
          <img src={nft1Pic} />
          <img src={nft2Pic} />
          <img src={nft3Pic} />
        </div>

      </div>
    )
  }
}

export default Team;