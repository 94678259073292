import React, { Component } from 'react';
import '../carousel.css';
import ninja1 from '../assets/1.jpg';
import ninja2 from '../assets/2.jpg';
import ninja3 from '../assets/3.jpg';
import ninja4 from '../assets/4.jpg';
import ninja5 from '../assets/5.jpg';
import ninja6 from '../assets/6.jpg';
import ninja7 from '../assets/7.jpg';
import ninja8 from '../assets/8.jpg';
import ninja9 from '../assets/9.jpg';

 
class Carousel extends Component { 

    render() {
        return (

            <div class="boxWrapC">
 
                <div class="slider">
                    <div class="slide-track2">
                        <div class="slide">
                            <img class="carouselIMG" src={ninja1} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja6} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja2} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja3} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja4} alt="ninja5" />
                        </div>
                   

                        <div class="slide">
                            <img class="carouselIMG" src={ninja7} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja8} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja9} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja1} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja2} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja5} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja3} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja4} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja5} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja6} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja7} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja8} alt="ninja5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={ninja9} alt="ninja5" />
                        </div>
    
                    </div>
                </div>
            </div>
        );
    }
}

export default Carousel;        